@import '../../style/global';

.Language {
  @include Font-Head-S;
  cursor: pointer;
  position: absolute;
  right: $metrics-s;
  top: $metrics-s;
  color: $color-white;
  opacity: 0.75;
  font-weight: 300;
  text-decoration: underline;

  @include media-small-desktop {
    right: $metrics-m;
    top: $metrics-m;
  }
}

.MobileView {
  margin-bottom: $metrics-s;
}
