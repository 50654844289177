@import '../../style/global';

.ArticleContainer {
  @include Font-Head-S;

  strong {
    font-weight: 700;
  }

  a {
    @include hover-opacity;
  }

  p {
    margin: $metrics-xs * 2 0;
    word-break: break-word;
  }

  h1 {
    @include Font-Head-N;
    @include media-tablet {
      @include Font-Head-L;
    }
  }

  h2 {
    @include Font-Head-M;
    @include media-tablet {
      @include Font-Head-N;
    }
  }

  h1, h2 {
    margin-top: $metrics-m;
    margin-bottom: $metrics-s;

    @include media-tablet {
      margin-top: $metrics-l;
      margin-bottom: $metrics-m;
    }
  }

  h3, h4 {
    margin-top: $metrics-s;
    margin-bottom: $metrics-xs;

    @include media-tablet {
      margin-top: $metrics-m;
      margin-bottom: $metrics-s;
    }
  }

  h3 {
    @include Font-Head-S;
    @include media-tablet {
      @include Font-Head-M;
    }
  }

  h4 {
    @include Font-Head-S;
  }

  ul {
    list-style: none; /* Remove default bullets */
    margin-top: $metrics-s + $metrics-xs;
    margin-bottom: $metrics-s + $metrics-xs;
    margin-left: $metrics-s + $metrics-xs; /* Added to make an display block effect */
  }

  ul li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $color-grey;; /* Change the color */
    transform: translateY(5px);
    font-weight: bold; /* If you want it to be bold */
    font-size: $fs-head-n;
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: $metrics-s; /* Also needed for space (tweak if needed) */
    margin-left: -($metrics-s) - $metrics-xs / 2; /* Also needed for space (tweak if needed) / minus value to display block effect */
  }

  ol {
    padding-left: $metrics-s;
  }

  img {
    width: 100%;
    @include grid-collapse;
    margin-top: $metrics-s;
    margin-bottom: -$metrics-xs;
  }

  .Container {
    @include grid-collapse;
  }

  .Link {
    display: inline-block;
    margin: $metrics-s auto;
    margin-bottom: $metrics-xl;
    background-color: $color-secondary-lighten;
    color: $color-primary;
    width: $metrics-xl * 2;
    white-space: nowrap;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px $metrics-s;
    @include Font-Head-S;

    @include media-tablet {
      @include grid-column(6);
    }

    @include media-small-desktop {
      @include Font-Body;
    }

    @include media-large-desktop {
      padding-right: 40px;
      @include Font-Head-S;
    }

    &:hover {
      background-color: $color-icons;
      color: $color-white;
      opacity: 1;

      span {
        transform: translateX(-$metrics-xs);
        transition: all 0.3s;
      }

      img {
        filter: invert(100%);
      }
    }

    span {
      display: block;
      transform: translateX(0);
    }

    img {
      display: block;
      width: $metrics-s;
      height: $metrics-s;
      margin: 0;
    }
  }
}
