@import '../../style/global';

.Footer {
  background-color: $color-primary;
  color: $color-white;
  padding: $metrics-m 0;
  @include Font-Head-S;

  @include media-small-desktop {
    padding: $metrics-xl 0;
  }
}

.InfoSection {
  @include grid-column(12);

  @include media-small-desktop {
    @include grid-column(5);
    padding-right: $metrics-s;
  }
}

.Links {
  padding: 0;
  @include grid-column(12);

  @include media-small-desktop {
    @include grid-column(4);
    padding: $metrics-s 0  $metrics-s $metrics-m;
    justify-content: flex-start;
  }
}

.Link {
  padding: $metrics-xs 0;

  @include media-tablet {
    padding: $metrics-xs;
  }
}

.Filters {
  color: $color-white;
  padding: $metrics-s 0;
  flex-direction: column;

  @include grid-column(12);

  @include media-small-desktop {
    @include grid-column(3);
    padding-left: $metrics-m;
    justify-content: flex-start;
  }
}

.SectionsContainer {
  overflow: unset;
}
