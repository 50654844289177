@import '../../../style/global';


.MoreArticles {
  @include Font-Head-S;
  @include grid-column(3, $mobile-grid);
  @include media-tablet {
    @include grid-column(12);
  }
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Image {
  width: 14px;
  height: 14px;
  margin-left: $metrics-xs;
  transform: rotate(-90deg)
}