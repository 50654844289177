@import '../../style/global';

.MainArticleContainer {
  display: block;
  cursor: pointer;
  color: $color-primary;
  background-color: $color-white;
  text-decoration: none;
  position: relative;
  padding-bottom: $metrics-s;

  &:hover {
    opacity: 1;
  }
  @include media-small-desktop {
    padding-bottom: $metrics-l;
  }
}

.AssetContainer {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-right: -50vw;
  cursor: pointer;

  @include media-small-desktop {
    height: 620px;
  }
}

.Image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background: top right;
  background-size: cover;
  transition: all .5s;
  position: relative;

  &:hover {
    transform: scale(1.2);
  }

  @include media-small-desktop {
    object-fit: cover;
  }
}

.Tags {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  position: relative;
  bottom: $metrics-s;;
  left: 0;
  height: $metrics-s;
  @include media-small-desktop {
    left: $metrics-s;
  }
}

.Title {
  @include Font-Head-M;
  padding: $metrics-xs 0 $metrics-m 0;
  @include grid-column(3, $mobile-grid);
  @include media-tablet {
    @include grid-column(6);
    padding: $metrics-s 0;
  }

  @include media-small-desktop {
    @include grid-column(4);
    padding: $metrics-s + $metrics-xs 0 $metrics-s 0;
  }
}
