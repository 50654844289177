@import '../../style/global';

@import '../../style/global';

.Container{
  padding: $metrics-m 0;
  @include grid-column(3, $mobile-grid);
  float: none;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include media-tablet {
    @include grid-collapse;
    @include grid-column(12);
    float: none;
    flex-direction: row;
    padding: $metrics-l 0;
  }

  @include media-small-desktop {
    @include grid-collapse;
    @include grid-column(9);
    @include grid-push(3);
  }
}

.Image {
  width: 100%;
  margin-bottom: $metrics-l;

  @include media-less-than-tablet {
    position: relative;
    width: 100vw;
  }

  @include media-tablet {
    @include grid-column(4);
    float: none;
    margin-left: 0;
  };
}

.InfoColumn {
  @include media-tablet {
    @include grid-column(6);
    @include grid-push(1);
    float: none;
    margin-bottom: $metrics-l;
  }
}
