@import '../../style/global';

.MainContainer {
  width: 100%;
  background-color: $color-white;
  @include Font-Head-S;
  padding-top: $metrics-xl;
}

.TextContainer {
  @include grid-column(3, $mobile-grid);
  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(6);
  }
}

.MainHeading {
  @include Font-Head-L;

  @include media-tablet {
    @include Font-Head-XL;
  }
}

.SmallDescription {
  padding: $metrics-m 0;
}

.Form {
  @include grid-column(3, $mobile-grid);
  padding: $metrics-m 0;
  display: flex;
  flex-direction: column;


  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(6);
  }
}

.Button {
  @include grid-column(3, $mobile-grid);
  @include Font-Body;
  width: $metrics-xxl;
  height: $metrics-m;
  line-height: $metrics-m;
  background-color: $color-icons;
  color: $color-white;
  border: none;
  cursor: pointer;
  margin: $metrics-m 0;
  align-self: center;

  @include media-small-desktop {
    align-self: flex-end;
  }
}

.CheckboxVisible {
  float: none;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 1;
  margin-bottom: $metrics-s;
  transform: translateY(0);
  pointer-events: auto;
  transition: all 0.5s ease;
  transition-delay: 0.3s;
  @include Font-XS-Text;
}

.CheckboxHidden {
  position: absolute;
  opacity: 0;
  transform: translateY(-25px);
  pointer-events: none;
}

.Popup {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(#000, 0.5);
    @include Font-Head-S;

  .InnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    padding: $metrics-s;
    background-color: $color-white;
    opacity: 0;
    animation: moveUp 0.65s ease forwards;

    @include media-tablet {
      max-width: 700px;
      padding: $metrics-m;
    }
  }

  .TextContainer, .Form {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  .CloseButton {
    position: absolute;
    top: $metrics-s;
    right: $metrics-s;

    @include media-tablet {
      right: $metrics-m;
    }
  }

  .MainHeading {
    @include Font-Head-N;

    @include media-mobile {
      @include Font-Head-L;
    }
  }

  .SmallDescription {
    padding: $metrics-xs 0 $metrics-s;

    @include media-tablet {
      padding-bottom: $metrics-m;
    }
  }

  .Form {
    padding: 0;
  }

  .Input {
    span, input {
      font-size: $fs-head-s;
      line-height: 1;
    }
  }

  .Button {
    margin: 0;
  }

  .CheckboxVisible {
    margin-top: -60px;

    label {
      @include Font-XS-Text;
      padding-left: $metrics-xs * 4;
    }

    @include media-mobile {
      margin-top: 0;
    }
  }

  @keyframes moveUp {
    0% {transform: translate(-50%, 0); }
    100% { transform:  translate(-50%, -50%); opacity: 1; }
  }
}
