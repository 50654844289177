@import '../../../style/global';

.Container {
  padding-bottom: $metrics-s;
  overflow: hidden;

  @include media-small-desktop {
    padding-bottom: $metrics-m;
  }
}

.Sections {
  color: $color-primary;
  @include Font-Body;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  @include media-tablet {
    @include grid-column(12);
  }
}

.CarouselSections {
  margin-left: $metrics-s;
}

.Section {
  padding: $metrics-xs 0;
  cursor: pointer;
  list-style: none;
  flex: 1 0 auto;
  @include Font-Body;

  @include media-tablet {
    flex: 0 1 auto;
    padding: $metrics-xs;
  }

  &:focus {
    outline: none;
  }
}

.Section:hover {
  color: $color-grey-lighten;
}

.SectionText {
  padding-bottom: 4px;
}

.Active, .Active:hover {
  border-bottom: 1px solid $color-primary;
}

.Counter {
  color: $color-grey-lighten;
}
