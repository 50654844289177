@import '../../style/global';


.BlogListing {
  background-color: $color-white;
  position: relative;
  padding-bottom: $metrics-m;
}

.Grid {
  background-color: $color-white;

  @include media-small-desktop {
    margin-bottom: $metrics-l;
  }
}

.ArticleBoxesContainer {
    width: 100%;
    background-color: $color-white;
    display: flex;
    flex-wrap: wrap;

  }

.ArticleTwoBoxesContainer {
  width: 100%;
  @include media-small-desktop {
    display: flex;
  }

  a:first-child {
    @include grid-column(3, $mobile-grid);

    @include media-tablet {
      @include grid-column(6);
    }

    @include media-small-desktop {
      @include grid-column(8);
    }
  }

  a:last-child {
    @include grid-column(3, $mobile-grid);

    @include media-tablet {
      @include grid-column(6);
    }

    @include media-small-desktop {
      @include grid-column(4);
    }
  }
}

.SubText {
  @include Font-Body;
  @include grid-column(3, $mobile-grid);
  padding-bottom: $metrics-m;

  @include media-tablet {
    @include grid-collapse;
    @include grid-column(10);
    @include grid-push(1);
  }
}

.ArticleBox {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}


.LoadMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.LoadMoreButton {
  display: block;
  height: $metrics-s;
  border: none;
  color: $color-primary;
  background-color: $color-white;
  @include Font-Head-S;;
  text-align: center;
  cursor: pointer;
}

.Image {
  width: 14px;
  height: 14px;
  margin-left: $metrics-xs;
}
