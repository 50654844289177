@import '../../style/global';

.Container {
  position: fixed;
  z-index: 1000;
  background-color: $color-grey-lighten;
  height: auto;
  bottom: 0;
  width: 100vw;
  transition: width 0.5s ease-out;

  @include media-tablet {
    bottom: 0;
    right: 0;
    width: 500px;
  }

  @include media-large-desktop {
    width: 550px;
  }

  @include media-extra-large-desktop {
    width: 650px;
  }
}

.Text {
  @include Font-Body;
  color: $color-white;
  padding: $metrics-m;
}

.Close {
  position: absolute;
  top: $metrics-s;
  right: $metrics-s;
  background: transparent;
  border: none;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  cursor: pointer;

  &:focus {
    outline: none;
}

  &::before, &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: $color-white;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
