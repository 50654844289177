@import '~bourbon-neat/core/neat';

//grid
$grid-column: 90px;
$grid-gutter: 30px;
$grid-width: 12 * $grid-column + 11 * $grid-gutter;
$neat-grid: ( columns: 12, gutter: $grid-gutter, );

//mobile-grid
$mobile-grid: ( columns: 3, gutter: $grid-gutter );

$opacity-transition-time: 0.2s;

@mixin hover-opacity {
  transition: opacity $opacity-transition-time;

  &:hover {
    opacity: 0.8;
  }
}

//colors
$color-primary: #161F25;
$color-primary-darken: #141516;
$color-secondary: #F0F0F0;
$color-secondary-lighten: #F4F4F4;
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #707070;
$color-grey-lighten: #757575;

$color-socials: #0000ff;
$color-danger: #EF3E33;
$color-icons: #5252FF;

//metrics
$metrics-xxl: 200px;
$metrics-xl: 145px;
$metrics-l: 85px;
$metrics-m: 60px;
$metrics-s: 30px;
$metrics-xs: 10px;

$metrics-blog-s: 30px;
$metrics-blog-m: 85px;
$metrics-blog-l: 115px;
$metrics-blog-xl: 145px;


//font-sizes
$fs-head-xl: 7.2rem;
$fs-head-l: 4.8rem;
$fs-head-n: 3.4rem;
$fs-head-m: 2.4rem;
$fs-head-s: 2rem;
$fs-body: 1.6rem;
$fs-xs-text: 1.3rem;

//line-heights
$lh-head-xl: 8.6rem;
$lh-head-l: 7.2rem;
$lh-head-n: 5.4rem;
$lh-head-m: 3.6rem;
$lh-head-s: 3rem;
$lh-body: 2.4rem;
$lh-xs-text: 1.9rem;


//fonts
@mixin Font-Head-XL {
  font-size: $fs-head-xl;
  line-height: $lh-head-xl;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-display: swap;
}

@mixin Font-Head-L {
  font-size: $fs-head-l;
  line-height: $lh-head-l;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-display: swap;
}

@mixin Font-Head-N {
  font-size: $fs-head-n;
  line-height: $lh-head-n;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin Font-Head-M {
  font-size: $fs-head-m;
  line-height: $lh-head-m;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin Font-Head-S {
  font-size: $fs-head-s;
  line-height: $lh-head-s;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin Font-Body {
  font-size: $fs-body;
  line-height: $lh-body;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
}

@mixin Font-XS-Text {
  font-size: $fs-xs-text;
  line-height: $lh-xs-text;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
}
