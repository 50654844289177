@import '../../../style/global';

.Container {
  padding-top: $metrics-blog-s;
  padding-bottom: $metrics-blog-s;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  width: 100%;
  @include Font-Body;
  @include media-tablet {
      padding-bottom: $metrics-blog-xl;
  }

  @include media-small-desktop {
    flex-direction: row;
    padding-top: $metrics-blog-xl;
  }
}

.ShareButtonsContainer {
  padding-top: $metrics-xl / 2;
  overflow: hidden;
  @include grid-column(3, $mobile-grid);

  margin-top: $metrics-s;

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(2);
    @include grid-push(1);
  }
}

.ShareButtons {
    display: flex;

    @include media-small-desktop {
      flex-direction: column;
    }
}


.ContentContainer {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(6);
    @include grid-push(3);
  }
}

.Content {
  margin-top: -55px;
}
