@import '../../style/global';

.Container {
  padding-top: $metrics-l;
  overflow: hidden;

  @include media-small-desktop {
    overflow: visible;
  }

  *::selection {
    background-color: $color-secondary;
  }
}

.Grid {
  margin-bottom: $metrics-s;
}

.FullWidthImage {
  padding: $metrics-blog-s 0;
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  object-fit: contain;
  z-index: 999;

  @include media-small-desktop {
    padding: $metrics-blog-m 0;
    height: 720px;
    object-fit: cover;
  }
}

.AudioContainer {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(6);
    @include grid-push(3);
  }
}

.ArticleBox {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}

.SliderArticleBox {
  margin-left: $metrics-s;
  margin-bottom: $metrics-m;
}

.ReadMoreText {
  @include Font-Head-M;
  @include grid-column(3, $mobile-grid);
  padding-bottom: $metrics-xs;

  @include media-tablet {
    padding-bottom: $metrics-l;
    @include grid-column(12);
  }
}

.VideoSection {
  height: $metrics-xxl * 2;
  margin: $metrics-blog-s 0;
  @include grid-column(3, $mobile-grid);
  float: none;

  @include media-tablet {
    @include grid-column(12);
    margin-top: $metrics-blog-m;
    float: none;
  }

  @include media-small-desktop {
    @include grid-column(6);
    @include grid-push(3);
    margin-bottom: $metrics-blog-s;
  }
}

.Carousel {
  @include media-less-than-tablet {
    height: 100%;
    top: 0;
  }
}

.ShareButtons {
  display: flex;
  background-color: $color-grey;
  padding: $metrics-xs/2;
  border-radius: 5px;

  &:after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $color-grey;
  }
}
