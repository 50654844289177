@import '../../../../style/global';

.ShareButton {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: $color-primary;
  padding: 6px;
  cursor: pointer;
  transition-duration: 0.2s;
  outline: none;
  font-weight: 500;
  @include media-small-desktop {
    padding: 0 6px;
  }
}

.ButtonText {
  @include Font-Body;
  color: $color-primary;
  &:hover {
    color: $color-socials;
  }
}
